<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <SPForm :form-type="formType" :form-category="formCategory" :form-id="formId" :is-pos="false" :ref-form="refForm"></SPForm>
            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import SPForm from "@/components/app/common/SPForm";

    export default {
        name: 'Sale_view',
        data: function() {
            return {
                pageName: 'Sale',
                segments: [
                    {link: true, text: 'Sales', routeName: 'App.Sales'},
                    {link: false, text: 'Sale'},
                ],
                formType: this.$globalEnums.formTypes.Sale,
                formCategory: this.$globalEnums.formCategories.Invoice,
                formId: 0,
                refForm: {
                    id: 0,
                    type: null
                }
            }
        },
        components: {
            SPForm,
            BreadCrumb
        },
        created() {
            this.formId = this.$route.params.id;
            this.formCategory = this.$route.params.type;
            this.refForm = this.$route.params.refForm;
        }
    }
</script>

<style scoped>

</style>
